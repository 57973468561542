<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png" alt @click="goBack" />
        <span class="center">发票管理</span>
        <img src="../../assets/right.png" alt @click="goBack" />
        <span class="center">发票列表</span>
        <img src="../../assets/right.png" alt />
        <span class="center">{{ title }}</span>
      </div>
    </div>
    <el-card class="box-card">
      <el-form
        label-position="certer"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="ruleForm"
        label-width="60px"
        style="text-align: left"
      >
        <Divider :title="'发票信息'"></Divider>
        <el-col :span="12">
          <el-form-item label="购买方" label-width="110px" prop="upperCode">
            <el-select
              style="width: 90%"
              v-model="ruleForm.upperCode"
              placeholder="请选择购买方"
              @change="taskListChange"
            >
              <el-option
                v-for="item in enterpriseList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发票图片" label-width="110px" prop="imgUrl">
            <el-upload
              class="avatar-uploader"
              :action="action"
              :show-file-list="false"
              :on-success="successFile"
              :before-upload="beforeAvatarUpload"
              accept=".png, .jpg, jpeg, JPG, JPEG"
            >
              <img
                v-if="ruleForm.imgUrl"
                :src="ruleForm.imgUrl"
                style="width:300px;height:150px"
                class="avatar"
              />
              <el-button style="margin-left:20px" type="primary" plain size="small" v-else>上传</el-button>
              <span
                slot="tip"
                class="el-upload__tip"
                style="display: inline-block;color: chocolate;margin: 0 10px;"
              >
                *340px*150px
                <span v-if="ruleForm.imgUrl">点击图片重新上传</span>
              </span>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发票备注" label-width="110px" prop="remark">
            <el-input
              v-model="ruleForm.remark"
              type="textarea"
              style="width: 100%"
              placeholder="请输入发票备注"
            ></el-input>
          </el-form-item>
        </el-col>
        <Divider :title="'工资包信息'" :tag="`已选择${taskDemos.length}个工资包，累计结算金额：￥${totalNum}`">
          <el-dropdown slot="right" @command="open">
            <el-button type="text" style="padding:0">添加工资包</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in  taskList"
                :disabled="item.isShow"
                :key="item.code"
                :command="item"
              >{{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </Divider>
        <div v-for="item in taskDemos" :key="item.code" style="margin:10px">
          <el-row>
            <el-col :span="12">工资包:{{item.name}}</el-col>
            <el-col :span="12">关联服务包:{{item.totalName}}</el-col>
          </el-row>
          <el-row>
            <el-col :span="12">工资包结算金额:{{item.total}}</el-col>
            <el-col :span="12">
              工资包创建时间:{{item.completeTime}}
              <el-popconfirm title="是否删除这条工资包记录吗？" @confirm="delTask(item.code)">
                <el-button slot="reference" type="text" style="color:red;float:right;padding:0">刪除</el-button>
              </el-popconfirm>
            </el-col>
          </el-row>
        </div>
        <Divider :title="'快递信息'"></Divider>
        <el-col :span="12">
          <el-form-item label="快递公司" label-width="110px" prop="expressName">
            <el-select style="width: 90%" v-model="ruleForm.expressName" placeholder="请选择快递公司">
              <el-option
                v-for="item in companyList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="快递单号" label-width="110px" prop="expressCode">
            <el-input v-model="ruleForm.expressCode" style="width: 100%" placeholder="请输入公司电话"></el-input>
          </el-form-item>
        </el-col>

        <div style="text-align: center">
          <el-button type="primary" v-if="title === '编辑发票'" @click="goSubmit('ruleForm')">提交</el-button>
          <el-button type="primary" v-else @click="onSubmit('ruleForm')">提交</el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { api, generatePath } from '../../api/base/index';
// import * as _ from "lodash";
export const customerAdd = api()('enterprise.add.json');
export const upperEnterpriseList = api()('upper_enterprise.list.json');
export const upperEnterpriseInvoiceTaskList = api()('upper_enterprise.invoice.taskList.json');
export const upperEnterpriseInvoiceAdd = api()('upper_enterprise.invoice.add.json');
export const upperEnterpriseInvoiceInfo = api()('upper_enterprise.invoice.info.json');
export const upperEnterpriseInvoiceEdit = api()('upper_enterprise.invoice.edit.json');
export default {
  components: {
    Divider: () => import('../../components/divider.vue'),
  },
  data () {
    return {
      authenticateState: '',
      code: '',
      fileList: [],
      title: '',
      enterpriseList: [],
      companyList: [
        { name: '圆通', code: '圆通' },
        { name: '中通', code: '中通' },
        { name: '百世', code: '百世' },
        { name: '申通', code: '申通' },
        { name: '韵达', code: '韵达' },
        { name: '顺丰', code: '顺丰' },
        { name: '京东', code: '京东' },
        { name: '邮政', code: '邮政' },
        { name: '其他', code: '其他' },
      ],
      action: '',
      uploadData: {
        OutTradeNo: '123456',
        PhotoType: '01'
      },
      ruleForm: {
        upperCode: '',
        imgUrl: '',
        remark: '',
        expressName: '',
        expressCode: ''
      },
      rules: {
        upperCode: [
          { required: true, message: '请选择上游企业', trigger: 'blur' },
        ],
      },
      taskList: [],
      taskDemos: [],
      oldtaskList: [],
      totalNum: 0

    };
  },
  async created () {
    this.action = window.location.origin + '/enterprise.upload.json';
    this.code = this.$route.query.code;
    this.getEnterpriseList()
    if (this.code) {
      this.title = '编辑发票';
      await this.logoData();
    } else {
      this.title = '添加发票'
    }
  },
  mounted () {
    this.action = generatePath('enterprise.upload.json')
  },
  methods: {
    open (command) {

      this.taskList.map(it => {
        if (it.code === command.code) {
          it.isShow = true
        }
      })
      this.taskDemos.push(command)

    },
    delTask (code) {

      if (this.title === '编辑发票') {

        if (this.taskList.some((i) => i.code === code)) {
          console.log(1111)
        }
        else {

          this.taskList.push(this.oldtaskList.filter(it => it.code === code)[0])
        }

      }

      this.taskList.map(it => {
        if (it.code === code) {
          it.isShow = false
        }
      })
      this.taskDemos = this.taskDemos.filter(it => it.code != code)

    },
    taskListChange (code, name) {
      if (name != '2') {
        this.taskDemos = []
      }
      const params = {
        code: code
      };
      upperEnterpriseInvoiceTaskList(params).then((res) => {
        this.taskList = res.map(it => ({ ...it, isShow: false }))
        if (this.taskDemos.length) {
          this.taskList = this.taskList.map(item => {
            this.taskDemos.map(val => {
              if (item.code == val.code) {
                item.isShow = true
              }
            })
            return item
          })

        }
      });

    },
    getEnterpriseList () {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      upperEnterpriseList(params).then((res) => {

        this.enterpriseList = res.list;

      });
    },
    successFile (res, fileList) {
      console.log(fileList);
      this.ruleForm.imgUrl = res.data;
      console.log(this.ruleForm.imgUrl);
    },
    beforeAvatarUpload (file) {
      console.log(file);
    },
    payChange (e) {
      console.log(e);
    },
    handleClick (tab, event) {
      console.log(tab, event);
    },
    goBack () {
      window.history.go(-1);
    },
    async logoData () {
      const params = {
        code: this.code,
      };
      const res = await upperEnterpriseInvoiceInfo(params);

      this.ruleForm.upperCode = res.upper.code
      this.ruleForm.imgUrl = res.imgUrl
      this.ruleForm.remark = res.remark
      this.ruleForm.expressName = res.expressName
      this.ruleForm.expressCode = res.expressCode
      this.taskDemos = res.task
      this.oldtaskList = res.task
      this.taskListChange(res.upper.code, '2')

    },
    close (formName) {
      this.$refs[formName].resetFields();
    },
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.taskDemos.length > 0) {
            upperEnterpriseInvoiceAdd({
              taskCodes: this.taskDemos.map(it => { return it.code }),
              ...this.ruleForm
            }).then((res) => {
              console.log(res)
              this.close(formName);
              window.history.back(-1);
            });
          }
          else {
            this.$message({
              message: '请选择工资包',
              type: 'info',
            });
          }
        } else {
          return false;
        }
      });
    },
    goSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.taskDemos.length > 0) {
            upperEnterpriseInvoiceEdit({
              taskCodes: this.taskDemos.map(it => { return it.code }),
              ...this.ruleForm,
              code: this.code,
            }).then(() => {
              this.close(formName);
              window.history.back(-1);
              this.$message({
                message: '保存成功',
                type: 'success',
              });
            });
          }
          else {
            this.$message({
              message: '请选择工资包',
              type: 'info',
            });
          }


        } else {
          return false;
        }
      });
    },


  },
};
</script>
<style lang="scss" scope>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
